.footer {
  background-color: #00534c;
  color: map-get($colors, light) !important;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 6rem;
  padding-top: 2%;
  display: flex;

  .app-footer {
    align-items: center;
    width: 100%;
  }
  .container {
    display: flex;
  }
  &__logo {
    padding: 0px;
    padding-left: 1rem;
  }
}
.footer-info {
  font-size: 12px;
  color: map-get($colors, light);
}
