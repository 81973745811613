.register-page {

  .form-wrapper{
    display: flex;
    width: 50%;
    .MuiTextField-root{
      width: 100% !important;
    }
  }

    @media (min-width:501) and (max-width: 1024px) {

      .form-wrapper{
        .MuiTextField-root{
          width: 100% !important;
        }
      }
    }
    @media (min-width:320px) and (max-width: 500px) {
      .form-wrapper{
        width: 100%;
        .MuiTextField-root{
          width: 100% !important;
        }
      }
    }
}
