.h-dark{
  background-color: map-get($colors, black) !important;
  img{
    width: 200px !important;
    height: 110px !important;
  }
}
.h{
  font-family: "Open-Sans-Bold";

}
.main-color {
  color: map-get($colors, dark);
}
.secondary-color {
  color: map-get($colors, secondary);
}
.secondary-bg {
  background-color: map-get($colors, secondary);
}
.label-color {
  color: map-get($colors, label-color);
}
.main-bg {
color: map-get($colors, dark);
}
.img-size {
  width: 130px;
}

.w-4 {
  width: 50%;
}
.w-7 {
  width: 90%;
}
.w-2 {
  width: 15%;
}
.btn-w {
  width: 20%;
}
.b-25 {
  border-radius: 25px;
}
.alignment-center {
  position: relative;
  display: flex;
  flex-direction: column;
  // padding-right: 68px;
  // padding-left: 68px;
  align-items: center;
}
//These styles belongs to TBS Game
.coffe_type:hover {
  cursor: pointer;
}
.coffe_type {
  width: 43%;
}
.btn-font {
  font-size: 20px;
}
.content.game-result {
  align-items: flex-start;
}
.custom-btn {
  border-radius: 5px;
}
.custom-i {
  font-size: 15px !important;
  margin-top: 5px !important;
}

.ge-font {
  font-family: "Open-Sans";
}
.ge-font-bold {
  font-family: "Open-Sans-Bold";
}
.ve-font {
  font-family: "Open-Sans";
}
.ve-font-bold {
  font-family: "Open-Sans-Bold";
  font-size: 28px;
}

.re-font {
  font-family: "Open-Sans-Bold";
}
.custom-font {
  font-size: 22px;
  font-weight: 650;
}
.w-1 {
  width: 100%;
}
.MuiFormLabel-root {
  // font-weight: 700 !important;
  font-size: 20px !important;
  font-family: "Open-Sans" !important;
  font-weight: bolder !important;
}
.MuiInputBase-root {
  font-size: 17px !important;
  font-weight: bolder !important;
}
.MuiFormLabel-root {
  color: map-get($colors, label-color) !important;
  font-family: "Open-Sans" !important;
}
.MuiInputBase-root {
  color: map-get($colors, dark) !important;
  font-family: "Open-Sans" !important;
}

.custom-payment {
  color: map-get($colors, dark) !important;
  font-size: 2rem !important;
  margin-bottom: 30px;
}
.MuiFormGroup-root {
  align-items: center;
}
.MuiRadio-colorSecondary.Mui-checked {
  color: map-get($colors, secondary) !important;
}
.userInfo {
  display: flex;
  align-items: center;
}
.user-custom-font {
  font-size: 35px;
}
.user-custom-font-2 {
  font-size: 14px;
}
.clientContent {
  display: flex;
}
.right-info {
  display: flex;
  align-items: center;
}
.right-info .btn-primary {
  border-radius: 0px !important;
  background-color: map-get($colors, light);
  color: map-get($colors, dark);
  border: none;
}
.c-img {
  width: 100px;
}
.custom-flex {
  flex-grow: 2;
}
.custom-flex img:hover {
  cursor: pointer;
}
.modal-content {
 // background-color:#fff !important;
  color: #000;
  //padding-bottom: 30px;
  width: 350px;
  height: 380px;
  //text-align: center;
 // margin-left: 50px;
}
.modal-body {
  padding: 3rem;
  font-size: 30px;
}
.modal-header,
.modal-footer {
  border-bottom: none !important;
  border-top: none !important;
}
.modal-footer {
  flex-direction: column;
}
.right-info .btn-primary:not(:disabled):not(.disabled):active,
.right-info .btn-primary:not(:disabled):not(.disabled).active,
.right-info .show > .btn-primary.dropdown-toggle,
.dropdown-item.active,
.dropdown-item:active {
  border-radius: 0px !important;
  background-color: map-get($colors, light);
  color: map-get($colors, dark);
  border: none;
}
.right-info .btn-primary:not(:disabled):not(.disabled):active:focus,
.right-info .btn-primary:not(:disabled):not(.disabled).active:focus,
.right-info .show > .btn-primary.dropdown-toggle:focus,
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
}
.dropdown-menu {
  color: #02544c;
  text-align: left;
  border: none;
 background-color:  map-get($colors, light);
}
.dropdown-item:hover,
.dropdown-item:focus {
  background-color: map-get($colors, light);
  color: map-get($colors, label-color);
}
.custom_select_option {
  width: 50%;
  text-align: left;
}

//Register Page Styles
.custom-text-field {
  margin-top: 16px !important;
  width: 48% !important;
}
//Game Style
.edit-cost-style input {
  color: map-get($colors, label-color) !important;
}
//Terms and Conditions Page
.ul-right {
  margin-left: -20px;
}
.edit-custom-btn {
  width: 100%;
  display: flex;
  //padding-left: 30px;
  flex-direction: column;
}

//Login PAge
.a-line {
  text-decoration: underline;
}

.page-title {
  font-size: 28px;
}
.custom-responsive-img {
  width: 180px;
  height: 260px;
}

//Edit Responsive
@media (min-width: 425px) and (max-width: 570px) {
  .custom-responsive-img {
    width: 90px;
    height: 120px;
  }
  .clientContent {
    margin-right: 25px;
    justify-content: center;
  }
  .payment-status-container {
    margin-top: 110px !important;
  }
  .game-header {
    margin-top: 90px !important;
  }
  .img-pop{
    width: 100% !important;
    height: 100% !important;
  }
}
@media (min-width: 768px) and(max-width:  1024px) {
  .w-2 {
    width: 35%;
  }
  .img-pop{
    width: 100% !important;
    height: 100% !important;
  }
}
@media (min-width: 320px) and (max-width: 570px) {
  .w-4,
  .w-7,
  .coffe_type {
    width: 100%;
  }
  .img-pop{
    width: 100% !important;
    height: 100% !important;
  }
  .w-2 {
    width: 50%;
  }
  // .modal-content {
  //   height: 385px;
  // }
  .Custom-re {
    margin-right: 23px !important;
  }
  .footer-info {
    font-size: 10px;
    margin-top: 13px;
  }
  .left-logo {
    width: 45%;
    float: left;
  }
  .mr-re {
    margin-right: 18px;
  }
  .right-info {
    flex-direction: column;
    align-items: flex-end;
  }
  .custom-m {
    margin-right: 20px !important;
  }
  .custom-font {
    font-size: 18px;
  }
  .alignment-center {
    // padding-right: 20px;
    // padding-left: 20px;
  }
  .re-cstom-font {
    font-size: 18px;
  }
  .MuiFormLabel-root {
    font-size: 20px !important;
  }
  .custom-text-field {
    width: 71% !important;
    margin-top: 16px;
  }
  .userInfo {
    flex-direction: column;
  }
  .userInfo > p {
    margin: 5px !important;
  }
  .custom-responsive-img {
    width: 90px;
    height: 120px;
  }

  .custom-media {
    margin-left: 1.5rem !important;
  }
  .clientContent {
    margin-right: 25px;
  }
  &.dropdown-menu {
    &.show {
      text-align: right;
      background-color: transparent;
    }
  }
  .img-size {
    width: 60px;
    margin-bottom: 10px;
  }
  .custom-img {
    width: 80%;
  }
  .custom_select_option {
    width: 100%;
  }
  .btn-w {
    width: 65%;
    margin-left: 40px;
  }
  .edit-ce-respo {
    margin-left: 40px;
  }

  .payment-status-container {
    margin-top: 110px !important;
  }
  .game-header {
    margin-top: 90px !important;
  }
}
