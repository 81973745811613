body {
  margin: 0;
  background-color: #fff;
  font-family: 'Open-Sans' !important;
}
body>div {
  position: relative;
  min-height: 100vh;
}


@font-face{
  font-family: 'Geometria-mid';
  src: local('Geometria-Medium'), url("./app/assets/fonts/Geometria-Medium.ttf") format("truetype");
  font-weight: 900;
}
@font-face{
  font-family: 'Open-Sans';
  src: local('open-sans.regular'), url("./app/assets/fonts/open-sans.regular.ttf") format("truetype");
  font-weight: 900;
}

  @font-face{
    font-family: 'Open-Sans-Bold';
    src: local('OpenSans-ExtraBold'), url("./app/assets/fonts/OpenSans-ExtraBold.ttf") format("truetype");
    font-weight: 900;
}
@font-face{
  font-family: 'Geometria-bold';
  src: local('Geometria-Medium'), url("./app/assets/fonts/Geometria-Bold.ttf") format("truetype");
  font-weight: 900;
}

@font-face{
  font-family: 'Radikal Bold';
  src: local('Radikal Bold'),  url("./app/assets/fonts/Radikal Bold.otf") format("opentype");
  font-weight: 900;
}
 @font-face{
  font-family: 'Verona-regular';
  src: local('Verona-regular'), url("./app/assets/fonts/Verona-Regular.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
 @font-face{
  font-family: 'Verona-bold';
  src: local('Verona-regular'), url("./app/assets/fonts/verona-bold.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
