.login-page {
  margin-top: 90px;

  .form-check {
      display: flex;
      align-items: center;

      .form-check-input{
         margin-top: 0.1rem; 
      }
  }
}
