.auth-page {
  // background-color: map-get($colors, dark);
  height: 100vh;
  .container {
    align-items: center;
    justify-content: center;
  }

  button {
    min-width: 115px;
  }

  .google-btn {
    margin: 10px !important;
    height: 40px !important;
    // color: white !important;
    border-color: white !important;
    // background-color: #4c8bf5 !important
     span {
      font-weight: 800 !important;
    }
  }

  @media (min-width: 320px) and (max-width: 570px) {
  }

}

.fb_reset{
  display: none;
}
.login-form {
  &__header {
    color: map-get($colors, light);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h2 {
      max-width: 375px;
      text-transform: uppercase;
      text-align: center;
    }
    img {
      margin-bottom: 25px;
      height: 290px;
      width: 350px;
    }
  }
  &__logo {
    margin-bottom: 10px;
    img {
      margin-bottom: 90px;
    }
  }

  @media (min-width: 320px) and (max-width: 570px) {
    &__logo {
      img {
        height: 105px;
        margin-bottom: 90px;
      }
    }
    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img {
        width: 200px  !important;
        height: 180px !important;
        margin-bottom: 25px;
      }
    }
  }

  label {
  color: map-get($colors, light);
    
  }
  
}
