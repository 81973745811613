.cupsClientContainer{
    max-width: 60%;
  }

    
@media (min-width: 320px) and (max-width: 570px) {
    .cupsClientContainer{
        max-width: 100%;
      }
      .clientContent{
          margin-right: 0px;
          justify-content: center;
      }
      .custom-media{
          margin-left: 0px !important;
      }
      .alignment-center{
        //  padding-right: 0px;
      }
      img{
          width: 40px !important;
          height: 50px !important;
      }
     
}
@media (min-width: 425px) and (max-width: 480px) {
    .cupsClientContainer{
        max-width: 100%;
      }
      img{
        width: 80px !important;
        height: 85px !important;

      }
}
@media (min-width: 480px) and (max-width: 570px) {
    .cupsClientContainer{
        max-width: 90%;
      }
      img{
        width: 80px !important;
        height: 85px !important;

      }
}


@media (min-width:570px) and  (max-width: 768px){ 
    .cupsClientContainer{
        max-width: 100%;
      }
      img{
        width: 80px !important;
        height: 100px !important;

      }
 }

 @media (min-width: 768px) and  (max-width: 992px){ 
    .cupsClientContainer{
        max-width: 95%;
      }
  }

  
 @media (min-width: 992px) and (max-width:1024px){ 
    .cupsClientContainer{
        max-width: 70%;
      }
  }