
.cupsContainer{
    max-width:37%;
  }

  
@media (min-width: 320px) and (max-width: 570px) {
    .cupsContainer{
        max-width: 100%;
      }
      .clientContent{
          margin-right: 0px;
          justify-content: center;
      }
      .custom-media{
          margin-left: 0px !important;
      }
      img{
          width: 90px !important;
          height: 100px !important;
      }
}
@media (min-width: 425px) and (max-width: 570px) {
    .cupsContainer{
        max-width: 85%;
      }
}


@media (min-width:570px) and  (max-width: 768px){ 
    .cupsContainer{
        max-width: 50%;
      }
 }