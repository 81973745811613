//this for override on bootstrap colors ,
$theme-colors: (
	"primary":#9D9D9D,
	"info": #717171,
	"danger": #f00
);
// this section for our them colors 
$colors: (
		 light: white,
		// light:#fcbf00,
		dark: #000000,
		main: #020202,
		secondary: #020202,
		light-blue: #72c3d6,
		label-color:#020202,
		border: #ccc,
		text: #020202,
		error: #e3050f,
		calendar: #8d8d8d,
		placeholder: #020202,
		black:#020202
);

@import "~bootstrap/scss/bootstrap";