// 1: Initialize
@import "init";

// 2: Bootstrap/Material framework includes
@import './_settings/variables.scss';
@import './_blocks/button.scss';
@import './_blocks/app-footer.scss';
@import './_blocks/dates-wrapper.scss';
@import './_components/footer.scss';
@import './_components/page-content.scss';
@import './_components/header.scss';
@import './_components/globalStyle.scss';
@import "./_page/auth-page.scss";
@import "./_page/register-page.scss";
@import "./_page/login-page.scss";
@import "./_page/cashierPanel.scss";
@import "./_page/clientHome.scss";




