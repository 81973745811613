
.bg-light {
  background-color: map-get($colors, light) !important;
  .content {
    background-color: inherit;
  }
}
.right-info a {
  font-size: 16px;
}

.darkBtn{
  background-color: #000;
}

.add-customer-mobile {
    display: none;
}

@media (min-width: 320px) and (max-width: 570px) {
  .add-customer {
    display: none;
  }
  .add-customer-mobile {
    display: unset;
  }
  .header-logo{
    width: 105px !important;
  }
  .dropdown-item{
    padding:0rem 1.5rem !important
  }
  .dropdown-menu{
    padding:0 !important;
    margin-bottom:0px;
    &.show{
      transform: translate3d(-68.5px, 30px, 0px) !important;
    }
  }
}
